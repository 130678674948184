import { FETCH_ACCOUNT, SET_ERROR_ACCOUNT } from './action'
import { getItem, removeItem } from '../../utility/LocalStorage'
import { decryptCrypto } from '../../utility/Encryption'
import { removeCookies } from '../../utility/Cookies'

let account = getItem('_wsst') ? decryptCrypto(getItem('_wsst')) : null
if (account && new Date().getTime() > account?.expires) {
  removeItem('_wsst')
  removeItem('_medXuAcSes')
  removeItem('_mVednXu')
  removeCookies('_wchat')
  account = null
}
const initialState = {
  account,
  error_account: true,
}

export default function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT:
      return {
        ...state,
        account: action.data,
      }
    case SET_ERROR_ACCOUNT:
      return {
        ...state,
        error_account: action.data,
      }
    default:
      return state
  }
}
