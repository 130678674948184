import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../services/ApiService'
import { showAlert } from '../../utility/SweetAlert'

const InteractorProductSearch = () => {
  const { t } = useTranslation()
  const productSearch = useCallback(
    (payload, callback, loading) => {
      ApiService.jsonRequest('/product-item/search', payload, response => {
        if (response.status_code === 200) {
          loading(false)
          callback(response.data)
          if (!response.data.items?.length) {
            showAlert({
              icon: 'error',
              message: t('message.data_empty'),
              timer: 1500,
            })
          }
        }
      })
    },
    [t],
  )

  return { productSearch }
}

export default InteractorProductSearch
