import { combineReducers } from 'redux'
import LoadingReducer from './loading/reducers'
import AccountReducer from './account/reducers'
import ChangeLayoutMode from './themeLayout/reducers'
import AccessReducer from './access/reducers'
import MenuReducer from './menu/reducers'
import SummaryHeaderReducer from './summary/header/reducers'

const rootReducers = combineReducers({
  loading: LoadingReducer,
  access: AccessReducer,
  menu: MenuReducer,
  account: AccountReducer,
  ChangeLayoutMode,
  summaryHeader: SummaryHeaderReducer,
})

export default rootReducers
