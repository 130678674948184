import Styled from 'styled-components'

const EditorContent = Styled.div`
    img{
        max-width: 100% !important;
        margin-bottom:16px;
    }
`

export { EditorContent }
