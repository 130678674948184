import { useCallback } from 'react'
import ApiService from '../../services/ApiService'
import { getItem } from '../../utility/LocalStorage'

const InteractorDownloadPDF = () => {
  const lng = getItem('language') ? getItem('language').toUpperCase() : 'ID'

  const getRFQ = useCallback(
    payload => {
      const resultPayload = {
        language: lng,
        ...payload,
      }
      const filename = `RFQ-${payload.request_code}.pdf`
      ApiService.fileRequest('/download-pdf/rfq', resultPayload, filename, 'application/pdf')
    },
    [lng],
  )

  const getTransaction = useCallback(
    payload => {
      const resultPayload = {
        language: lng,
        ...payload,
      }
      const filename = `ORDER-${payload.transaction_code}.pdf`
      ApiService.fileRequest(
        '/download-pdf/transaction',
        resultPayload,
        filename,
        'application/pdf',
      )
    },
    [lng],
  )

  const getInvoice = useCallback(
    payload => {
      const resultPayload = {
        language: lng,
        ...payload,
      }
      const filename = `INVOICE-${payload.invoice_code}.pdf`
      ApiService.fileRequest('/download-pdf/invoice', resultPayload, filename, 'application/pdf')
    },
    [lng],
  )

  const getFeeInvoice = useCallback(
    payload => {
      const resultPayload = {
        language: lng,
        ...payload,
      }
      const filename = `FEE-INVOICE-${payload.fee_code}.pdf`
      ApiService.fileRequest(
        '/download-pdf/fee-invoice',
        resultPayload,
        filename,
        'application/pdf',
      )
    },
    [lng],
  )

  return { getRFQ, getTransaction, getInvoice, getFeeInvoice }
}

export default InteractorDownloadPDF
