import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchSummaryHeader } from '../../redux/summary/header/actionCreator'
import ApiService from '../../services/ApiService'
import { setCookies } from '../../utility/Cookies'
import { encryptCrypto } from '../../utility/Encryption'

const InteractorSummaryHeader = () => {
  const { account } = useSelector(state => state.account)
  const dispatch = useDispatch()

  const summaryHeader = useCallback(() => {
    if (account) {
      // CHAT
      ApiService.jsonRequest(
        '/communication-chat/message-summary',
        {
          chat_to_role: 'CS',
          chat_to_reference_uid: '0',
        },
        response => {
          if (response.status_code === 200) {
            const chat_unread = response.data?.total_unread ?? 0

            // NOTIFICATION
            ApiService.jsonRequest(
              '/communication-notification/total-unread',
              {
                notification_target: 'CMS',
                account_uid: '0',
              },
              res => {
                let notification_unread = 0
                if (res.status_code === 200) {
                  notification_unread = res.data.total_unread ?? 0
                }
                let summary = {}
                summary.chat_unread = chat_unread
                summary.notification_unread = notification_unread
                setCookies({
                  key: 'cms-summary-header',
                  value: encryptCrypto(JSON.stringify(summary)),
                  expires: new Date().getTime() + 5 * 60 * 1000, // 5 Minutes
                })
                dispatch(FetchSummaryHeader(summary))
              },
            )
          }
        },
      )
    }
  }, [account, dispatch])

  return { summaryHeader }
}

export default InteractorSummaryHeader
