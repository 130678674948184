import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../../services/ApiService'
import { showAlert } from '../../../../utility/SweetAlert'

const InteractorMasterMenuTeamView = () => {
  const { t } = useTranslation()

  const menuTeamView = useCallback(
    (payload, callback, loading) => {
      ApiService.jsonRequest('/master-menu/team-view', payload, response => {
        if (response.status_code === 200) {
          callback(response.data)
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 1500,
            redirect: '/master/menu-team/manage',
          })
        }
        loading(false)
      })
    },
    [t],
  )
  return { menuTeamView }
}

export default InteractorMasterMenuTeamView
