import { useCallback } from 'react'
import ApiService from '../../services/ApiService'
import { decryptCrypto, encryptCrypto } from '../../utility/Encryption'
import { getItem, setItem } from '../../utility/LocalStorage'

const InteractorTransactionReport = () => {
  const dashboardCms = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    const session_data = getItem('dashboard-cms') ? decryptCrypto(getItem('dashboard-cms')) : null
    if (session_data && session_data?.expires > new Date().getTime()) {
      callback(session_data)
      loading(false)
    } else {
      ApiService.jsonRequest('/transaction-report/dashboard-cms', payload, response => {
        if (response.status_code === 200) {
          loading(false)
          let rows = { ...response.data }
          rows.expires = new Date().getTime() + 5 * 60 * 1000 // 5 Minutes
          callback(rows)
          setItem('dashboard-cms', encryptCrypto(JSON.stringify(rows)))
        }
      })
    }
  }, [])

  return { dashboardCms }
}

export default InteractorTransactionReport
