import React, { useLayoutEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TopMenuStyle } from './style'

const TopMenu = () => {
  const { t } = useTranslation()
  const list_menu = useSelector(state => state.menu.list)

  useLayoutEffect(() => {
    const active = document.querySelector('.strikingDash-top-menu a.active')
    const activeDefault = () => {
      const megaMenu = active.closest('.megaMenu-wrapper')
      const hasSubMenuLeft = active.closest('.has-subMenu-left')
      if (!megaMenu) {
        active.closest('ul').previousSibling.classList.add('active')
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active')
      } else {
        active.closest('.megaMenu-wrapper').previousSibling.classList.add('active')
      }
    }
    window.addEventListener('load', active && activeDefault)
    return () => window.removeEventListener('load', activeDefault)
  }, [])

  const addParentActive = event => {
    document.querySelectorAll('.parent').forEach(element => {
      element.classList.remove('active')
    })

    const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left')
    const megaMenu = event.currentTarget.closest('.megaMenu-wrapper')
    if (!megaMenu) {
      event.currentTarget.closest('ul').previousSibling.classList.add('active')
      if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active')
    } else {
      event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active')
    }
  }
  return (
    <TopMenuStyle>
      <div className="strikingDash-top-menu">
        <ul>
          {list_menu?.map((row, key) => {
            return row.menu_level === 1 ? (
              <li key={key} className={!row.children ? '' : 'has-subMenu'}>
                {!row.children ? (
                  <Link to={row.menu_path}>{t(`menu.${row.menu_name}`)}</Link>
                ) : (
                  <Link to="#" className="parent">
                    {t(`menu.${row.menu_name}`)}
                  </Link>
                )}
                {row.children?.length && (
                  <ul className="subMenu">
                    {row.children.map((children, idx) => {
                      return (
                        <li key={idx}>
                          <NavLink to={children.menu_path} onClick={addParentActive}>
                            {t(`menu.${children.menu_name}`)}
                          </NavLink>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </li>
            ) : null
          })}
        </ul>
      </div>
    </TopMenuStyle>
  )
}

export default TopMenu
