import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { confirmationAlert, showAlert } from '../../../utility/SweetAlert'

const InteractorMasterSchemaUpdate = () => {
  const { t } = useTranslation()
  const schemaUpdate = useCallback(
    (payload, loading) => {
      confirmationAlert({
        message: t('message.confirm_process'),
        icon: 'question',
      }).then(result => {
        if (result === true) {
          ApiService.jsonRequest('/master-schema/update', payload, response => {
            loading(false)
            if (response.status_code === 200) {
              showAlert({
                icon: 'success',
                message: t('message.successfully_updated'),
                timer: 1500,
                redirect: '/master/schema/manage',
              })
            } else {
              showAlert({
                icon: 'error',
                message: t(`response_message.${response.messages}`),
                timer: 1500,
              })
            }
          })
        } else {
          loading(false)
        }
      })
    },
    [t],
  )
  return { schemaUpdate }
}

export default InteractorMasterSchemaUpdate
