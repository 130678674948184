import React, { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root'
import { Provider, useSelector, useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import store from './redux/store'
import Board from './routes/board'
import Auth from './routes/auth'
import './assets/css/style.css'
import config from './config/config'
import ProtectedRoute from './components/utilities/protectedRoute'
import { Loading } from './components/Main'
import {
  InteractorAuthorization,
  InteractorAccess,
  InteractorSummaryHeader,
} from './interactors/Main'
import { ShowLoading } from './redux/loading/actionCreator'
import { getCookies } from './utility/Cookies'
import { getItem } from './utility/LocalStorage'
import ScrollToTop from './routes/ScrollToTop'
import FirebaseNotification from './firebase/FirebaseNotification'

const { theme } = config

const ProviderConfig = () => {
  const { rtl, isLoggedIn, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.account.account,
    }
  })
  const [path, setPath] = useState(window.location.pathname)
  const { loading } = useSelector(state => state.loading)
  const dispatch = useDispatch()
  const { token } = InteractorAuthorization()
  const { listAccess } = InteractorAccess()
  const { summaryHeader } = InteractorSummaryHeader()

  useEffect(() => {
    if (!getCookies('_wide')) {
      dispatch(ShowLoading())
      token()
    }
    if (isLoggedIn && !getItem('_mVednXu')) {
      dispatch(ShowLoading())
      listAccess()
    }
    if (isLoggedIn && !getCookies('cms-summary-header')) {
      summaryHeader()
    }
    let unmounted = false
    if (!unmounted) {
      setPath(window.location.pathname)
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true)
  }, [setPath, dispatch, token, listAccess, isLoggedIn, summaryHeader])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
          <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
            <Router basename={process.env.PUBLIC_URL}>
              <ScrollToTop>
                {!isLoggedIn ? (
                  <Route path="/" component={Auth} />
                ) : (
                  <ProtectedRoute path="/" component={Board} />
                )}
                {isLoggedIn &&
                  (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
                    <Redirect to="/" />
                  )}
              </ScrollToTop>
              {isLoggedIn && <FirebaseNotification />}
            </Router>
          </ThemeProvider>
        </ConfigProvider>
      )}
    </>
  )
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  )
}

export default hot(App)
