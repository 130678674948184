import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../services/ApiService'
import { confirmationAlert, showAlert } from '../../utility/SweetAlert'

const InteractorUploadFile = () => {
  const { t } = useTranslation()
  const storageCreate = useCallback(
    (body, loading) => {
      let payload = {
        ...body,
      }
      let fSize = Math.round(payload.file.size / 1024)
      if (fSize < 5000) {
        confirmationAlert({
          message: t('message.confirm_process'),
          icon: 'question',
        }).then(result => {
          if (result === true) {
            ApiService.formRequest('/storage-file/upload', payload, response => {
              loading(false)
              if (response.status_code === 200) {
                showAlert({
                  icon: 'success',
                  message: t('message.successfully_created'),
                  timer: 1500,
                  redirect: '/storage/manage',
                })
              } else {
                showAlert({
                  icon: 'error',
                  message: t(`response_message.${response.messages}`),
                  timer: 1500,
                })
              }
            })
          } else {
            loading(false)
          }
        })
      } else {
        showAlert({
          icon: 'error',
          message: t(`message.upload_failed_storage`),
          timer: 2000,
        })
      }
    },
    [t],
  )
  return { storageCreate }
}

export default InteractorUploadFile
