import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorMasterProvinceList = () => {
  const provinceList = useCallback((body, callback) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/master-province/list', payload, response => {
      if (response.status_code === 200) {
        const { data } = response
        callback(data)
      }
    })
  }, [])

  return { provinceList }
}

export default InteractorMasterProvinceList
