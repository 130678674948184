import React, { lazy, Suspense } from 'react'
import { Spin } from 'antd'
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthLayout from '../containers/authentication/Index'

const Login = lazy(() => import('../containers/authentication/SignIn'))

const NotFound = () => {
  return <Redirect to="/" />
}

const FrontendRoutes = () => {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/" component={Login} />
        <Route exact path="*" component={NotFound} />
      </Suspense>
    </Switch>
  )
}

export default AuthLayout(FrontendRoutes)
