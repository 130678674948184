import React, { useEffect } from 'react'
import propTypes from 'prop-types'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { findByArray } from '../../utility/Helpers'
import { showAlert } from '../../utility/SweetAlert'

const ProtectedRoute = ({ component, path }) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const list_access = useSelector(state => state.access.list)
  const protect_path = list_access ? findByArray(list_access, 'menu_path', pathname) : null
  const unprotected_path = [
    '/account/profile',
    '/account/change-password',
    '/chat',
    '/notification',
    '/download/pdf',
  ]
  useEffect(() => {
    if (!unprotected_path.includes(pathname)) {
      if (list_access && !protect_path) {
        showAlert({
          icon: 'warning',
          message: t(`response_message.dont_have_permission_to_access`),
          timer: 2000,
        })
      }
    }
  }, [list_access, pathname, protect_path, t, unprotected_path])

  const isLoggedIn = useSelector(state => state.account.account)
  return (isLoggedIn && protect_path) || unprotected_path.includes(pathname) ? (
    <Route component={component} path={path} />
  ) : (
    <Redirect to="/" />
  )
}

ProtectedRoute.propTypes = {
  component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
}

export default ProtectedRoute
