import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../services/ApiService'
import { showAlert } from '../../utility/SweetAlert'

const InteractorProductVariantDelete = () => {
  const { t } = useTranslation()
  const productVariantDelete = useCallback(
    (payload, callback = () => {}, loading = () => {}) => {
      ApiService.jsonRequest('/product-variant/delete', payload, response => {
        loading(false)
        if (response.status_code === 200) {
          showAlert({
            icon: 'success',
            message: t(`response_message.data_has_been_deleted`),
            timer: false,
          })
          callback()
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: false,
          })
        }
      })
    },
    [t],
  )
  return { productVariantDelete }
}

export default InteractorProductVariantDelete
