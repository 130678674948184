import { useCallback } from 'react'
import ApiService from '../../services/ApiService'
import { removeCookies } from '../../utility/Cookies'

const InteractorNotificationUpdate = () => {
  const notificationUpdateRead = useCallback((payload, path) => {
    ApiService.jsonRequest('/communication-notification/update', payload, response => {
      if (response.status_code === 200) {
        removeCookies('cms-summary-header')
        window.location.href = path
      }
    })
  }, [])
  return { notificationUpdateRead }
}

export default InteractorNotificationUpdate
