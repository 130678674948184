import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FetchAccount } from '../../redux/account/actionCreator'
import ApiService from '../../services/ApiService'
import { encryptCrypto } from '../../utility/Encryption'
import { setItem } from '../../utility/LocalStorage'
import { confirmationAlert, showAlert } from '../../utility/SweetAlert'

const InteractorAccountProfileUpdate = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const accountUpdate = useCallback(
    (payload, loading) => {
      confirmationAlert({
        message: t('message.confirm_process'),
        icon: 'question',
      }).then(result => {
        if (result === true) {
          ApiService.jsonRequest('/team-account/update', payload, response => {
            loading(false)
            if (response.status_code === 200) {
              let data = { ...response.data }
              data.expires = new Date().getTime() + 8 * 3600 * 1000
              setItem('_wsst', encryptCrypto(JSON.stringify(data)))
              dispatch(FetchAccount(data))
              showAlert({
                icon: 'success',
                message: t('message.successfully_updated'),
                timer: 1500,
                redirect: '/',
              })
            } else {
              showAlert({
                icon: 'error',
                message: t(`response_message.${response.messages}`),
                timer: 1500,
              })
            }
          })
        } else {
          loading(false)
        }
      })
    },
    [dispatch, t],
  )
  return { accountUpdate }
}

export default InteractorAccountProfileUpdate
