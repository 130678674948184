import { LIST_ACCESS } from './action'

export const StoreAccess = data => {
  return async dispatch => {
    dispatch({
      type: LIST_ACCESS,
      data,
    })
  }
}
