import React, { lazy, Suspense } from 'react'
import { Spin } from 'antd'
import { Switch, Route } from 'react-router-dom'
import withAdminLayout from '../../layouts/withAdminLayout'

const Dashboard = lazy(() => import('./dashboard'))
const Account = lazy(() => import('./account'))
const Storage = lazy(() => import('./storage'))
const Team = lazy(() => import('./team'))
const Master = lazy(() => import('./master'))
const Content = lazy(() => import('./content'))
const Authentication = lazy(() => import('./authentication'))
const Customer = lazy(() => import('./customer'))
const Company = lazy(() => import('./company'))
const Store = lazy(() => import('./store'))
const Newsletter = lazy(() => import('./newsletter'))
const Fund = lazy(() => import('./fund'))
const Ticket = lazy(() => import('./ticket'))
const Product = lazy(() => import('./product'))
const Rfq = lazy(() => import('./rfq'))
const Transaction = lazy(() => import('./transaction'))

const Chat = lazy(() => import('../../containers/chat/Chat'))
const Notification = lazy(() => import('../../containers/notification/Notification'))
const DownloadPdf = lazy(() => import('../../containers/download/DownloadPDF'))

const Board = () => {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path="/" component={Dashboard} />
        <Route path="/account" component={Account} />
        <Route path="/customer" component={Customer} />
        <Route path="/company" component={Company} />
        <Route path="/store" component={Store} />
        <Route path="/product" component={Product} />
        <Route path="/chat" component={Dashboard} />
        <Route path="/ticket" component={Dashboard} />
        <Route path="/rfq" component={Rfq} />
        <Route path="/transaction" component={Transaction} />
        <Route path="/authentication" component={Authentication} />
        <Route path="/storage" component={Storage} />
        <Route path="/master" component={Master} />
        <Route path="/content" component={Content} />
        <Route path="/team" component={Team} />
        <Route path="/fund" component={Fund} />
        <Route path="/newsletter" component={Newsletter} />
        <Route path="/ticket" component={Ticket} />
        <Route path="/chat" component={Chat} />
        <Route path="/notification" component={Notification} />
        <Route path="/download/pdf" component={DownloadPdf} />
      </Suspense>
    </Switch>
  )
}

export default withAdminLayout(Board)
