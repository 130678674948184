import Styled from 'styled-components'

const Background = Styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background: url("${require('../../assets/img/pages/bg_login_wide_commerz.png')}");
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 800px){
    display:none;
  }
`

const Content = Styled.div`
  display: flex;
  background: #FCF9EDCC;
  flex-flow: column;
  align-items:center;
  justify-content: center;
  height:100%;
  padding:100px;
  text-align:center;
  @media only screen and (max-width: 768px){
    display:none;
  }
  @media only screen and (max-width: 800px){
    display:none;
  }
  .logo{
    width:60%;
  }
  .title{
    font-size: 28px;
    margin-bottom: 0.25em;
  }
  .subtitle{
    font-size: 16px;
    font-weight: lighter;
    max-width: 70%;
  }
`

const AuthWrapper = Styled.div`
  display: flex;
  flex-flow: column;
  align-item: center;
  justify-content: center;
  height: 80vh;
  padding: 40px;
  @media only screen and (max-width: 1599px){
    padding: 25px;
  }
  
  @media only screen and (max-width: 767px){
    text-align: center;
  }
  .auth-notice{
    text-align: right;
    font-weight: 500;
    color: ${({ theme }) => theme['gray-color']};
    @media only screen and (max-width: 767px){
      text-align: center;
      margin-bottom: 10px;
    }
  }
  button{
    &.btn-signin{
      min-width: 70%;
    }
    &.btn-create{
      border-radius: 8px;
      min-width: 205px;
    }
    &.btn-reset{
      border-radius: 8px;
      min-width: 260px;
    }
    &.ant-btn-lg{
      font-size: 14px;
      font-weight: 500;
      height: 48px;
    }
  }
  .auth-captha{
    margin-top: 15px;
    margin-bottom: 25px;
  }
  .auth-contents{
    display: flex;
    align-items: center;
    justify-content: center;
    form{
      width: 420px;
      text-align:center;  
      h1{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 45px;
        @media only screen and (max-width: 767px){
          margin-bottom: 28px;
        }
        input::placeholder{
          color: ${({ theme }) => theme['extra-light-color']};
        }
        p{
          font-size:18px;
          font-weight: 400;
          margin-top:8px;
        }

        span{
          color:  ${({ theme }) => theme['primary-color']};
        }
      }
      .auth-form-action{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 379px){
          flex-flow: column;
          .forgot-pass-link{
            margin-top: 15px;
          }
        }
      }
    }
    #forgotPass{
      .forgot-text{
        margin-bottom: 25px;
      }
      .return-text{
        margin-top: 35px;
      }
    }
    .form-divider{
      font-size: 13px;
      color: ${({ theme }) => theme['gray-solid']};
      text-align: center;
      position: relative;
      margin-bottom: 25px;
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        height: 1px;
        background: ${({ theme }) => theme['border-color-light']};
      }
      span{
        background: #fff;
        padding: 0 15px;
        display: inline-block;
        position: relative;
        z-index: 2;
      }
    }
    .ant-form-item-label > label{
      font-size:16px;
      font-weight:300;
    }
  }
`

const LoginFormSide = Styled.div`
    .logo{
        width: 125px;
        margin: 16px 24px;
    }
`

export { Content, AuthWrapper, LoginFormSide, Background }
