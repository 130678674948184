import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { confirmationAlert, showAlert } from '../../../utility/SweetAlert'

const InteractorFundWithdrawUpdate = () => {
  const { t } = useTranslation()
  const fundWithdrawUpdate = useCallback(
    (payload, loading, withdraw_code) => {
      confirmationAlert({
        message: `${
          payload.status === 'REJECTED'
            ? t('message.confirm_reject_withdraw')
            : t('message.confirm_approve_withdraw')
        } #${withdraw_code}`,
        icon: 'question',
      }).then(result => {
        if (result === true) {
          ApiService.jsonRequest('/fund-withdraw/update', payload, response => {
            loading(false)
            if (response.status_code === 200) {
              showAlert({
                icon: 'success',
                message: t('message.successfully_updated'),
                timer: 1500,
                reload: true,
              })
            } else {
              showAlert({
                icon: 'error',
                message: t(`response_message.${response.messages}`),
                timer: 1500,
              })
            }
          })
        } else {
          loading(false)
        }
      })
    },
    [t],
  )
  return { fundWithdrawUpdate }
}

export default InteractorFundWithdrawUpdate
