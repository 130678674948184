import React, { useState } from 'react'
import { Avatar, Badge } from 'antd'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style'
import { Popover } from '../../popup/popup'
import { Dropdown } from '../../dropdown/dropdown'
import Heading from '../../heading/heading'
import { removeItem, setItem } from '../../../utility/LocalStorage'
import { removeCookies } from '../../../utility/Cookies'
import { getLanguage } from '../../../utility/Language'

const AuthInfo = () => {
  const { t } = useTranslation()
  const { account } = useSelector(state => state.account)
  const { header_info } = useSelector(state => state.summaryHeader)
  const [state, setState] = useState({
    flag: getLanguage('id').flag,
  })
  const { flag } = state

  const setLanguage = lang => {
    setItem('language', lang)
    window.location.reload(false)
  }

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value,
    })
  }

  const SignOut = e => {
    e.preventDefault()
    removeItem('_wsst')
    removeItem('_medXuAcSes')
    removeItem('_mVednXu')
    removeCookies('_wchat')
    window.location.reload(false)
  }

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img
            src={account.profile_picture ?? require('../../../assets/img/avatar/no-image.png')}
            style={{ maxWidth: '46px' }}
            alt=""
          />
          <figcaption>
            <Heading as="h5">{account.full_name}</Heading>
            <p>{account.team_role}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/account/profile">
              <FeatherIcon icon="user" /> {t('menu.profile')}
            </Link>
          </li>
          <li>
            <Link to="/account/change-password">
              <FeatherIcon icon="unlock" /> {t('menu.change_password')}
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> {t('menu.signout')}
        </Link>
      </div>
    </UserDropDwon>
  )

  const country = (
    <NavAuth>
      <Link
        onClick={() => {
          onFlagChangeHandle('indonesia')
          setLanguage('id')
        }}
        to="#"
      >
        <img
          src={require('../../../assets/img/flag/indonesia.png')}
          style={{ maxWidth: '24px' }}
          alt=""
        />
        <span>Indonesia</span>
      </Link>
      <Link
        onClick={() => {
          onFlagChangeHandle('english')
          setLanguage('en')
        }}
        to="#"
      >
        <img
          src={require('../../../assets/img/flag/english.png')}
          style={{ maxWidth: '20px' }}
          alt=""
        />
        <span>English</span>
      </Link>
    </NavAuth>
  )

  return (
    <InfoWraper>
      <div className="message">
        <Link to="/chat" className="head-example">
          <Badge count={header_info.chat_unread ?? 0} overflowCount={10} offset={[5, -3]}>
            <FeatherIcon icon="message-circle" size={22} />
          </Badge>
        </Link>
      </div>
      <div className="notification">
        <Link to="/notification" className="head-example">
          <Badge count={header_info.notification_unread ?? 0} overflowCount={10} offset={[5, -3]}>
            <FeatherIcon icon="bell" size={22} />
          </Badge>
        </Link>
      </div>
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../assets/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div>

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar
              src={account.profile_picture ?? require('../../../assets/img/avatar/no-image.png')}
            />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  )
}

export default AuthInfo
