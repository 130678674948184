import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'

const InteractorMasterBankView = () => {
  const { t } = useTranslation()

  const bankView = useCallback(
    (payload, callback, loading) => {
      ApiService.jsonRequest('/master-bank/view', payload, response => {
        if (response.status_code === 200) {
          callback(response.data)
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 1500,
            redirect: '/master/bank/manage',
          })
        }
        loading(false)
      })
    },
    [t],
  )

  return { bankView }
}

export default InteractorMasterBankView
