import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import InteractorMasterMenuTeamList from './InteractorMasterMenuTeamList'

const InteractorMasterMenuTeamTree = () => {
  const { t } = useTranslation()
  const [masterMenuTeam, setMasterMenuTeam] = useState([])
  const { listMenu } = InteractorMasterMenuTeamList()

  const menuTeamTree = useCallback(
    (callback, expands, checkeds, disabled) => {
      if (masterMenuTeam.length === 0) {
        listMenu({ application_initial: 'CMS' }, setMasterMenuTeam)
      }
      let menuExpand = []
      let menuChecked = []
      const recursion = (items, id = null, link = 'menu_parent_uid') =>
        items
          .filter(item => item[link] === id)
          .map(item => {
            let tree = {
              title: t(`menu.${item.menu_name}`),
              key: item.uid,
              disabled,
            }
            if (item.menu_name === 'home') {
              tree.disabled = true
              menuChecked.push(item.uid)
            }
            const children = recursion(items, item.uid)
            if (children.length > 0) {
              menuExpand.push(item.uid)
              tree.children = children
            }
            return tree
          })
      callback(recursion(masterMenuTeam))
      expands(menuExpand)
      checkeds(menuChecked)
    },
    [listMenu, masterMenuTeam, t],
  )

  return { menuTeamTree }
}

export default InteractorMasterMenuTeamTree
