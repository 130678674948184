import React from 'react'
import { Menu } from 'antd'
import { NavLink, useRouteMatch } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const { SubMenu } = Menu

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch()

  const pathName = window.location.pathname
  const pathArray = pathName.split(path)
  const mainPath = pathArray[1]
  const mainPathSplit = mainPath.split('/')

  const { t } = useTranslation()
  const list_menu = useSelector(state => state.menu.list)

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  )

  const onOpenChange = keys => {
    setOpenKeys(
      keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys,
    )
  }

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([])
  }

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? 'home'
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'home'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {list_menu?.map(row => {
        return row.menu_level === 1 ? (
          !row.children ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={row.menu_path}>
                    <FeatherIcon icon={row.menu_icon} />
                  </NavLink>
                )
              }
              key={row.uid}
            >
              <NavLink onClick={toggleCollapsed} to={row.menu_path}>
                {t(`menu.${row.menu_name}`)}
              </NavLink>
            </Menu.Item>
          ) : (
            <SubMenu
              key={row.uid}
              icon={!topMenu && <FeatherIcon icon={row.menu_icon} />}
              title={t(`menu.${row.menu_name}`)}
            >
              {row.children?.length &&
                row.children.map(children => {
                  return (
                    <Menu.Item key={children.uid}>
                      <NavLink onClick={toggleCollapsed} to={children.menu_path}>
                        {t(`menu.${children.menu_name}`)}
                      </NavLink>
                    </Menu.Item>
                  )
                })}
            </SubMenu>
          )
        ) : null
      })}
    </Menu>
  )
}

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
}

export default MenuItems
