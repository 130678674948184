import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'

const InteractorUploadFile = () => {
  const { t } = useTranslation()
  const upload = useCallback(
    (body, callback, loading = () => {}) => {
      let payload = {
        ...body,
      }
      let limitSize = Math.round(payload.file.size / 1024 / 1024)
      if (limitSize < process.env.REACT_APP_MAX_FILE_UPLOAD_MB) {
        ApiService.formRequest('/storage-file/upload', payload, response => {
          loading(false)
          if (response.status_code === 200) {
            callback(response.data)
          }
        })
      } else {
        loading(false)
        showAlert({
          icon: 'error',
          message: `${t('message.upload_failed_storage')} ${
            process.env.REACT_APP_MAX_FILE_UPLOAD_MB
          } MB`,
          timer: 2000,
        })
      }
    },
    [t],
  )
  return { upload }
}

export default InteractorUploadFile
