import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorFundPaymentSearch = () => {
  const fundPaymentSearch = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/fund-payment/search', payload, response => {
      if (response.status_code === 200) {
        loading(false)
        callback(response.data)
      }
    })
  }, [])
  return { fundPaymentSearch }
}

export default InteractorFundPaymentSearch
