import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreAccess } from '../../redux/access/actionCreator'
import { StoreMenu } from '../../redux/menu/actionCreator'
import { HideLoading } from '../../redux/loading/actionCreator'
import { encryptCrypto } from '../../utility/Encryption'
import { findByArray } from '../../utility/Helpers'
import { setItem } from '../../utility/LocalStorage'
import InteractorMasterMenuTeamList from '../master/menu/team/InteractorMasterMenuTeamList'

const InteractorAccess = () => {
  const { listMenu } = InteractorMasterMenuTeamList()
  const account = useSelector(state => state.account.account)
  const dispatch = useDispatch()

  const handleResponse = useCallback(
    data => {
      const recursion = (items, id = null, link = 'menu_parent_uid') =>
        items
          .filter(item => item[link] === id)
          .map(item => {
            let tree = { ...item }
            tree.children = null
            const children = recursion(items, item.uid)
            if (children.length > 0) {
              tree.children = children
            }
            return tree
          })
      // FIND ACCESS
      let mapping = []
      account.team_menu.forEach(team => {
        const menu_uid = findByArray(data, 'uid', team.team_menu_uid)
        if (menu_uid) {
          if (menu_uid.menu_parent_uid) {
            const from_parent = findByArray(data, 'uid', menu_uid.menu_parent_uid)
            mapping.push(from_parent)
          }
          mapping.push(menu_uid)
        }
      })
      // GROUP
      const access = Array.from(new Set(mapping.map(a => a.uid))).map(id => {
        return mapping.find(a => a.uid === id)
      })
      // SORT
      access.sort((a, b) => (a.menu_level > b.menu_level ? 1 : -1))
      access.sort((a, b) => (a.menu_sort_number > b.menu_sort_number ? 1 : -1))

      // ROUTE PROTECTED
      setItem('_medXuAcSes', encryptCrypto(JSON.stringify(access)))
      dispatch(StoreAccess(access))

      // MENU
      const result = recursion(access)
      setItem('_mVednXu', encryptCrypto(JSON.stringify(result)))
      dispatch(StoreMenu(result))
      dispatch(HideLoading())
    },
    [account, dispatch],
  )
  const listAccess = useCallback(() => {
    listMenu({ application_initial: 'CMS' }, handleResponse)
  }, [handleResponse, listMenu])

  return { listAccess }
}

export default InteractorAccess
