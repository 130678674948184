import { LIST_ACCESS } from './action'
import { decryptCrypto } from '../../utility/Encryption'
import { getItem } from '../../utility/LocalStorage'

const initialState = {
  list: getItem('_medXuAcSes') ? decryptCrypto(getItem('_medXuAcSes')) : null,
}

export default function AccessReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_ACCESS:
      return {
        ...state,
        list: action.data,
      }
    default:
      return state
  }
}
