import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../services/ApiService'
import { confirmationAlert, showAlert } from '../../utility/SweetAlert'

const InteractorVerifyPassword = () => {
  const { t } = useTranslation()
  const verifyPassword = useCallback(
    (payload, loading) => {
      confirmationAlert({
        message: t('message.confirm_process'),
        icon: 'question',
      }).then(result => {
        if (result === true) {
          ApiService.jsonRequest(
            '/team-account/verify-password',
            {
              uid: payload.uid,
              password: payload.old_password,
            },
            response => {
              if (response.status_code === 200) {
                ApiService.jsonRequest(
                  '/team-account/change-password',
                  {
                    uid: payload.uid,
                    password: payload.new_password,
                  },
                  responseChange => {
                    loading(false)
                    if (responseChange.status_code === 200) {
                      showAlert({
                        icon: 'success',
                        message: t('message.successfully_updated'),
                        timer: 1500,
                        redirect: '/',
                      })
                    } else {
                      showAlert({
                        icon: 'error',
                        message: t(`response_message.${response.messages}`),
                        timer: 1500,
                      })
                    }
                  },
                )
              } else {
                loading(false)
                showAlert({
                  icon: 'error',
                  message: t(`response_message.${response.messages}`),
                  timer: 1500,
                })
              }
            },
          )
        } else {
          loading(false)
        }
      })
    },
    [t],
  )
  return { verifyPassword }
}

export default InteractorVerifyPassword
