import { useCallback } from 'react'
import ApiService from '../../services/ApiService'

const InteractorNotificationSearch = () => {
  const notificationSearch = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/communication-notification/search', payload, response => {
      if (response.status_code === 200) {
        loading(false)
        callback(response.data)
      } else {
        loading(false)
      }
    })
  }, [])

  return { notificationSearch }
}

export default InteractorNotificationSearch
