import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { confirmationAlert, showAlert } from '../../../utility/SweetAlert'

const InteractorStoreAccountCreate = () => {
  const { t } = useTranslation()
  const storeAccountCreate = useCallback(
    (payload, loading) => {
      confirmationAlert({
        message: t('message.confirm_process'),
        icon: 'question',
      }).then(result => {
        if (result === true) {
          let schema_history = payload.schema
          const { schema, ...body } = payload
          ApiService.jsonRequest('/store-account/create', body, response => {
            if (response.status_code === 200) {
              const store_uid = response.data?.uid
              schema_history.store_uid = store_uid
              ApiService.jsonRequest('/store-schema/create', schema_history, () => {
                loading(false)
                ApiService.jsonRequest(
                  '/fund-balance/create',
                  {
                    account_uid: payload.account_uid,
                    balance_amount: 0,
                    balance_amount_currency: 'IDR',
                    created_by: payload.created_by,
                  },
                  () => {
                    // Store Owner Access
                    ApiService.jsonRequest(
                      '/store-account/access-create',
                      {
                        store_uid,
                        account_uid: payload.account_uid,
                        role: 'OWNER',
                        created_by: payload.created_by,
                      },
                      () => {},
                    )
                    showAlert({
                      icon: 'success',
                      message: t('message.successfully_created'),
                      timer: 1500,
                      redirect: '/store/account/manage',
                    })
                  },
                )
              })
            } else {
              loading(false)
              showAlert({
                icon: 'error',
                message: t(`response_message.${response.messages}`),
                timer: 1500,
              })
            }
          })
        } else {
          loading(false)
        }
      })
    },
    [t],
  )
  return { storeAccountCreate }
}

export default InteractorStoreAccountCreate
