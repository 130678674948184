import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorMasterCountryList = () => {
  const countryList = useCallback((body, callback) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/master-country/list', payload, response => {
      if (response.status_code === 200) {
        callback(response.data)
      }
    })
  }, [])

  return { countryList }
}

export default InteractorMasterCountryList
