export const currencyValue = {
  IDR: 'Rp',
}

export const thousandSeparator = value => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const currencyConverter = (value, currency_type) => {
  return currencyValue[currency_type]
    ? `${currencyValue[currency_type]} ${thousandSeparator(value)}`
    : value
}
