import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { showAlert } from '../../../../utility/SweetAlert'
import { FetchAccount } from '../../../../redux/account/actionCreator'
import ApiService from '../../../../services/ApiService'
import { setItem } from '../../../../utility/LocalStorage'
import { encryptCrypto } from '../../../../utility/Encryption'
import { HideLoading, ShowLoading } from '../../../../redux/loading/actionCreator'

const InteractorTeamAccountLogin = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const login = useCallback(
    async body => {
      dispatch(ShowLoading())
      const payload = {
        ...body,
        logged_user_agent: navigator?.userAgent,
        logged_ip_address: null,
        logged_location: null,
        logged_device: null,
      }
      ApiService.jsonRequest('/team-account/login', payload, response => {
        dispatch(HideLoading())
        if (response.status_code === 200) {
          let data = { ...response.data }
          data.expires = new Date().getTime() + 8 * 3600 * 1000
          setItem('_wsst', encryptCrypto(JSON.stringify(data)))
          dispatch(FetchAccount(response.data))
          window.location.reload(false)
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 1500,
            reload: true,
          })
        }
      })
    },
    [dispatch, t],
  )

  return { login }
}

export default InteractorTeamAccountLogin
