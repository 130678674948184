import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorMasterRoleListView = () => {
  const viewRole = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/master-role/view', payload, response => {
      if (response.status_code === 200) {
        loading(false)
        callback(response.data)
      }
    })
  }, [])
  return { viewRole }
}

export default InteractorMasterRoleListView
