import { useCallback } from 'react'
import ApiService from '../../services/ApiService'

const InteractorCompanyMediaList = () => {
  const companyMediaList = useCallback((body, callback) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/account-company/media-list', payload, response => {
      if (response.status_code === 200) {
        callback(response.data)
      }
    })
  }, [])

  return { companyMediaList }
}

export default InteractorCompanyMediaList
