import React from 'react'
import { Row, Col } from 'antd'
import { Background, Content, LoginFormSide } from './style'

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row style={{ height: '100vh' }}>
        <Col lg={11} md={24} sm={24}>
          <LoginFormSide>
            <img className="logo" src={require('../../assets/img/adaloka.png')} alt="header-logo" />
            <WraperContent />
          </LoginFormSide>
        </Col>
        <Col md={13} sm={24}>
          <Background>
            <Content>
              <img className="logo" src={require('../../assets/img/adaloka.png')} alt="" />
              <h2 className="title">Content Management System</h2>
              <h3 className="subtitle">PT. Widea Dagang Nusantara</h3>
            </Content>
          </Background>
        </Col>
      </Row>
    )
  }
}

export default AuthLayout
