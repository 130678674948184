import { SUMMARY_HEADER } from './actions'
import { getCookies } from '../../../utility/Cookies'
import { decryptCrypto } from '../../../utility/Encryption'

const initialState = {
  header_info: getCookies('cms-summary-header')
    ? decryptCrypto(getCookies('cms-summary-header'))
    : {
        chat_unread: 0,
        notification_unread: 0,
      },
}

export default function SummaryHeaderReducer(state = initialState, action) {
  switch (action.type) {
    case SUMMARY_HEADER:
      return {
        ...state,
        header_info: action.header_info,
      }
    default:
      return {
        ...state,
      }
  }
}
