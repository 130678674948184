import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import ApiService from '../../../../services/ApiService'
import { setCookies } from '../../../../utility/Cookies'
import { encryptCrypto } from '../../../../utility/Encryption'
import { FetchAccount } from '../../../../redux/account/actionCreator'

const InteractorTeamAccountNotificationToken = () => {
  const dispatch = useDispatch()
  const syncToken = useCallback(
    (payload, push_token) => {
      const account = { ...payload }
      account.notification_token = push_token
      const bodyPayload = {
        uid: account.uid,
        notification_token: push_token,
      }
      ApiService.jsonRequest('/team-account/sync-push-token', bodyPayload, () => {
        setCookies({
          key: '_wsst',
          value: encryptCrypto(JSON.stringify(account)),
          expires: new Date().getTime() + 8 * 3600 * 1000, // 8 Hours
        })
        dispatch(FetchAccount(account))
      })
    },
    [dispatch],
  )
  return { syncToken }
}

export default InteractorTeamAccountNotificationToken
