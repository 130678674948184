import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorFundEscrowSearch = () => {
  const fundEscrowSearch = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/fund-escrow/search', payload, response => {
      if (response.status_code === 200) {
        loading(false)
        callback(response.data)
      }
    })
  }, [])
  return { fundEscrowSearch }
}

export default InteractorFundEscrowSearch
