import { useCallback } from 'react'
import ApiService from '../../../../services/ApiService'

const InteractorFundAccountBankView = () => {
  const fundAccountBankView = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/fund-account/bank-view', payload, response => {
      if (response.status_code === 200) {
        loading(false)
        callback(response.data)
      }
    })
  }, [])
  return { fundAccountBankView }
}

export default InteractorFundAccountBankView
