import { getItem } from './LocalStorage'

const lng = getItem('language') ? getItem('language') : 'id'

const getLanguage = () => {
  let data = {
    id: 'ID',
    title: 'Bahasa Indonesia',
    flag: 'indonesia',
  }
  if (lng !== 'id') {
    data = {
      id: 'EN',
      title: 'English',
      flag: 'english',
    }
  }
  return data
}

export { getLanguage }
