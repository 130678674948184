import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../services/ApiService'
import { showAlert } from '../../utility/SweetAlert'

const InteractorCompanyMediaCreate = () => {
  const { t } = useTranslation()
  const companyMediaCreate = useCallback(
    (uid, payload) => {
      ApiService.jsonRequest('/account-company/media-create', payload, response => {
        if (response.status_code === 200) {
          showAlert({
            icon: 'success',
            message: t('message.successfully_created'),
            timer: 1500,
            redirect: `/company/update?uid=${uid}`,
          })
        }
      })
    },
    [t],
  )
  return { companyMediaCreate }
}

export default InteractorCompanyMediaCreate
