import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

const ScrollToTop = propsTypes => {
  const props = propsTypes
  const location = useLocation()
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location])
  return <>{props.children}</>
}

ScrollToTop.propTypes = {
  children: PropTypes.any,
}
export default ScrollToTop
