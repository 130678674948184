import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../services/ApiService'
import { showAlert } from '../../utility/SweetAlert'

const InteractorRfqQuotationView = () => {
  const { t } = useTranslation()

  const quotationView = useCallback(
    (payload, callback, loading) => {
      ApiService.jsonRequest('/rfq-quotation/view', payload, response => {
        if (response.status_code === 200) {
          callback(response.data)
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 1500,
          })
        }
        loading(false)
      })
    },
    [t],
  )

  return { quotationView }
}

export default InteractorRfqQuotationView
