import { useCallback } from 'react'
import ApiService from '../../services/ApiService'

const InteractorCompanyList = () => {
  const companyList = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/account-company/list', payload, response => {
      if (response.status_code === 200) {
        callback(response.data)
      }
      loading(false)
    })
  }, [])

  return { companyList }
}

export default InteractorCompanyList
