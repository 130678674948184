import { SUMMARY_HEADER } from './actions'

export const FetchSummaryHeader = data => {
  return async dispatch => {
    dispatch({
      type: SUMMARY_HEADER,
      header_info: data,
    })
  }
}
