import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../../services/ApiService'
import { confirmationAlert, showAlert } from '../../../../utility/SweetAlert'

const InteractorTeamAccountCreate = () => {
  const { t } = useTranslation()
  const accountCreate = useCallback(
    (body, loading) => {
      const payload = {
        ...body,
      }
      confirmationAlert({
        message: t('message.confirm_process'),
        icon: 'question',
      }).then(result => {
        if (result === true) {
          ApiService.jsonRequest('/team-account/create', payload, response => {
            loading(false)
            if (response.status_code === 200) {
              showAlert({
                icon: 'success',
                message: t('message.successfully_created'),
                timer: 1500,
                redirect: '/team/manage',
              })
            } else {
              showAlert({
                icon: 'error',
                message: t(`response_message.${response.messages}`),
                timer: 1500,
              })
            }
          })
        }
      })
    },
    [t],
  )
  return { accountCreate }
}

export default InteractorTeamAccountCreate
