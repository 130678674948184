import React, { useState, useEffect, useCallback } from 'react'
import { Divider } from 'antd'
import FontAwesome from 'react-fontawesome'
import toast, { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { requestForToken, onMessageListener } from './FirebaseInitialize'
import { InteractorTeamAccountNotificationToken } from '../interactors/Main'
import { removeCookies } from '../utility/Cookies'
import { FetchSummaryHeader } from '../redux/summary/header/actionCreator'
import { StyleNotification } from '../components/style/StyleComponent'

const FirebaseNotification = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { header_info } = useSelector(state => state.summaryHeader)
  const { account } = useSelector(state => state.account)
  const { syncToken } = InteractorTeamAccountNotificationToken()
  const [notification, setNotification] = useState({ title: '', body: '', type: '' })
  const notify = () => toast(<ToastDisplay />)
  function ToastDisplay() {
    return (
      <StyleNotification>
        <Link to="/notification">
          <div className="bubble">
            <div className="top">
              <FontAwesome
                className="fa"
                name={
                  notification.type === 'RFQ'
                    ? 'file'
                    : notification.type === 'INFO'
                    ? 'info'
                    : notification.type === 'PAYMENT'
                    ? 'credit-card'
                    : notification.type === 'ORDER'
                    ? 'shopping-cart'
                    : 'info'
                }
              />
              <div>
                <strong>{notification?.title}</strong>
                <p>{notification?.body}</p>
              </div>
            </div>
            <Divider />
            <div className="bottom">
              <p>{t('title.click_detail')}</p>
            </div>
          </div>
        </Link>
      </StyleNotification>
    )
  }

  const getToken = useCallback(async () => {
    const browser_token = await requestForToken()
    if (browser_token && browser_token !== account?.notification_token) {
      syncToken(account, browser_token)
    }
  }, [account, syncToken])

  useEffect(() => {
    getToken()

    if (notification?.title) {
      notify()
    }
  }, [getToken, notification])

  if (onMessageListener) {
    onMessageListener()
      .then(payload => {
        if (payload?.data?.notification_target === 'CMS') {
          // RESET BADGE
          removeCookies('cms-summary-header')
          let summary = { ...header_info }
          if (payload?.data?.notification_type !== 'NEW-CHAT') {
            summary.notification_unread += 1
          } else {
            summary.chat_unread += 1
          }
          dispatch(FetchSummaryHeader(summary))
          // END RESET BADGE

          setNotification({
            title: payload?.notification?.title,
            body: payload?.notification?.body,
            type: payload?.data?.notification_type,
          })
        }
      })
      .catch(() => {})
  }
  return (
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      width="500px"
      containerClassName=""
      containerStyle={{
        top: 20,
        right: 30,
      }}
      toastOptions={{
        duration: 5000,
      }}
    />
  )
}

export default FirebaseNotification
