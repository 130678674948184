import { LIST_MENU } from './action'
import { decryptCrypto } from '../../utility/Encryption'
import { getItem } from '../../utility/LocalStorage'

const initialState = {
  list: getItem('_mVednXu') ? decryptCrypto(getItem('_mVednXu')) : null,
}

export default function MenuReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_MENU:
      return {
        ...state,
        list: action.data,
      }
    default:
      return state
  }
}
