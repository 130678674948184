import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'

const InteractorMasterProvinceView = () => {
  const { t } = useTranslation()

  const provinceView = useCallback(
    (uid, callback, loading) => {
      const payload = {
        uid,
      }
      ApiService.jsonRequest('/master-province/view', payload, response => {
        if (response.status_code === 200) {
          callback(response.data)
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 1500,
          })
        }
        loading(false)
      })
    },
    [t],
  )

  return { provinceView }
}

export default InteractorMasterProvinceView
