import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../services/ApiService'
import { showAlert } from '../../utility/SweetAlert'

const InteractorProductVariantCreate = () => {
  const { t } = useTranslation()
  const productVariantCreate = useCallback(
    (payload, callback = () => {}, loading = () => {}) => {
      ApiService.jsonRequest('/product-variant/create', payload, response => {
        loading(false)
        if (response.status_code === 200) {
          showAlert({
            icon: 'success',
            message: t('message.successfully_updated'),
            timer: 1500,
          })
          callback(response.data)
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 1500,
          })
        }
      })
    },
    [t],
  )
  return { productVariantCreate }
}

export default InteractorProductVariantCreate
