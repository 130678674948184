import moment from 'moment'
import i18n from './i18n'
import { thousandSeparator } from '../constant/global/CurrencyConverter'

export const groupByArray = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  )

export const getArrayString = (arr, key) => {
  let result = []
  for (let index = 0; index < arr.length; index++) {
    result.push(arr[index][key])
  }
  return result
}

export const findByArray = (array, key, value) => {
  return array.find(element => {
    return element[key] === value
  })
}

export const objToFormValue = data => {
  let initial = []
  Object.keys(data).forEach(key => {
    const obj = {
      name: key,
      value: data[key],
    }
    initial.push(obj)
  })

  return initial
}

export const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`
}

export const formatDate = (value, format) => {
  if (value !== undefined) {
    const unix_timestamp = Date.parse(new Date(value).toString())
    return moment(unix_timestamp).format(format)
  }
  return null
}

export const allowedText = (value, options) => {
  let text = value
  let optionsText = {
    lower_case: options.lower_case ?? false,
    upper_case: options.upper_case ?? false,
    stricted_symbol: options.stricted_symbol ?? null,
    space_convert: options.space_convert ?? null,
    number_only: options.number_only ?? null,
    password: options.password ?? null,
    dimension: options.dimension ?? null,
    price: options.price ?? null,
    weight: options.weight ?? null,
  }

  if (optionsText.lower_case) {
    text = text.toLowerCase()
  }

  if (optionsText.upper_case) {
    text = text.toUpperCase()
  }

  if (optionsText.number_only) {
    text = text.replace(/[^0-9]+/g, '')
  }

  if (optionsText.password) {
    text = text.replace(/'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'/, '')
  }

  if (optionsText.dimension) {
    text = text.replace(/^[0-9.]+(,\d{0,2})?$/, '')
  }

  if (optionsText.price) {
    text = text.replace(/^[0-9.]+(d{0,2})?$/, '')
  }

  if (optionsText.weight) {
    text = text.replace(/[^d+(.d{1,2})$]/g, '').slice(0, -1)
  }

  if (optionsText.stricted_symbol) {
    let regex = ''
    let conditions = optionsText.stricted_symbol
    if (conditions !== true) {
      regex = `[^a-zA-Z0-9 ${conditions?.length && conditions}${optionsText.space_convert?.length &&
        optionsText.space_convert}]`
    } else {
      regex = `[^a-zA-Z0-9 ${optionsText.space_convert?.length && optionsText.space_convert}]`
    }
    regex = new RegExp(regex)
    text = text.replace(regex, '')
  }

  if (optionsText.space_convert === '') {
    text = text.replace(/\s/g, '')
  } else if (optionsText.space_convert) {
    text = text.replace(/\s/g, optionsText.space_convert)
  }

  return text
}

export const validateWhitespaceInput = value => {
  return value.replace(/^\s+|\s+$|\s+(?=\s)/g, '')
}

export const numberFormatDestroy = value => {
  if (!value) return 0
  const parsed = typeof value === 'string' || value instanceof String ? value : value.toString()
  return parseFloat(parsed.replace(/([^,\d]+)/g, '').replace(',', '.'))
}

export const getExtension = filename => {
  return filename.split('.').pop()
}

export const generateFilename = (name, extension) => {
  const time = moment().format('YYYYMMDDHHmmss')
  const str = name.replace(/\s+/g, '-').toUpperCase()
  return `${str}-${time}${extension}`
}

export const numberFormat = (value, options = { leadingZeroAllowed: false }) => {
  if (!value && (value !== 0 || value !== '0')) return ''
  let parsed = typeof value === 'string' || value instanceof String ? value : value.toString()
  if (!options.leadingZeroAllowed) parsed = parsed.replace(/^[0]+/g, '')
  let number_string = parsed.replace(/[^,\d]/g, '')
  let split = number_string.split(',')
  let sisa = split[0].length % 3
  let rupiah = split[0].substring(0, sisa)
  let ribuan = split[0].substring(sisa).match(/\d{3}/gi)

  if (ribuan) {
    let separator = sisa ? '.' : ''
    rupiah += separator + ribuan.join('.')
  }

  rupiah = split[1] !== undefined ? `${rupiah},${split[1]}` : rupiah
  return rupiah
}

export const decimalFormat = (value = { leadingZeroAllowed: false }) => {
  if (value.charAt(0) === '0') {
    if (value.length === 2 && value.charAt(1) !== ',') {
      return '0,'
    }
    return value
  }
  let parsed = typeof value === 'string' || value instanceof String ? value : value.toString()
  // if (!options.leadingZeroAllowed) parsed = parsed.replace(/^[0]+/g, '')
  let number_string = parsed.replace(/[^,\d]/g, '')
  let split = number_string.split(',')
  let sisa = split[0].length % 3
  let rupiah = split[0].substring(0, sisa)
  let ribuan = split[0].substring(sisa).match(/\d{3}/gi)

  if (ribuan) {
    let separator = sisa ? '.' : ''
    rupiah += separator + ribuan.join('.')
  }

  rupiah = split[1] !== undefined ? `${rupiah},${split[1]}` : rupiah
  return rupiah
}

export const moneyFormat = value => {
  const lookup = [
    { value: 1e9, symbol: ' Miliar' },
    { value: 1e12, symbol: ' Triliun' },
    { value: 1e15, symbol: ' Kuadriliun' },
    { value: 1e18, symbol: ' Kuintiliun' },
  ]

  let item = lookup
    .slice()
    .reverse()
    .find(row => {
      return value >= row.value
    })

  if (item) {
    const format = parseFloat(value / item.value).toString()
    let plus = ''
    if (format.split('.')[1]) {
      plus = '+'
    }
    return format.split('.')[0] + plus + item.symbol
  }
  return thousandSeparator(value)
}

export const removeBeforeSlash = value => {
  return value.replace(/^.*\/(.*)$/, '$1')
}

export const testRegex = () => {
  return /^[\d.]{1,6}(,\d{0,2})?$/
}

export const arraySorter = (array, field, ascending) => {
  if (!field) {
    return array
  }
  let order = ascending ?? true
  let sorted = []
  let fallback = findByArray(array, field, 'N') ? 'N' : 'Z'
  if (order) {
    sorted = []
      .concat(array)
      .sort((a, b) =>
        typeof a[field] === 'string' || a[field] === null
          ? allowedText(a[field] ? a[field] : fallback, { upper_case: true }) >
            allowedText(b[field] ? b[field] : fallback, { upper_case: true })
            ? 1
            : -1
          : a[field] > b[field]
          ? 1
          : -1,
      )
  } else {
    sorted = []
      .concat(array)
      .sort((a, b) =>
        typeof a[field] === 'string' || a[field] === null
          ? allowedText(a[field] ? a[field] : 'A', { upper_case: true }) <
            allowedText(b[field] ? b[field] : 'A', { upper_case: true })
            ? 1
            : -1
          : a[field] < b[field]
          ? 1
          : -1,
      )
  }

  return sorted
}

export const sorterDashboard = (array, field, field2) => {
  let sorted = [].concat(array).sort((a, b) => {
    let isZero =
      a[field2] - a[field2]
        ? -1
        : a[field2] - b[field2] === 0
        ? allowedText(a[field] ? a[field] : 'Z', { upper_case: true }) >
          allowedText(b[field] ? b[field] : 'Z', { upper_case: true })
          ? 1
          : -1
        : 1
    return isZero
  })

  return sorted
}

export const translate = (option, value) => {
  let text = []
  let optionInput = {
    title: option.title ?? false,
    label: option.label ?? false,
  }

  if (optionInput.title) {
    text = `${i18n.t(`title.${value}`)}`
  }
  if (optionInput.label) {
    text = `${i18n.t(`label.${value}`)}`
  }

  return text
}

export const sortByKey = (dataArr, sortArr) => {
  let sortedArr = []
  sortArr.map(itemSort => {
    dataArr.map(item => {
      if (item.name === itemSort) {
        sortedArr.push(item)
      }
      return null
    })
    return null
  })

  return sortedArr
}

export const wordBreakSpace = string => {
  let customItems = string
  customItems = customItems.split(' ')

  for (let i = 0; i < customItems.length; i++) {
    customItems[i] = `${customItems[i]}\n`
  }
  customItems = customItems.join('')

  return customItems
}

const MAX_LENGTH_PER_ROW = 22
const countWord = (word, counter, arrPerRow, result) => {
  let wordLength = 0
  let countLetter = counter
  let newWord = ''
  const text = [...word]
  text.forEach((letter, index) => {
    if (['i', 'j', 'l', '!', '.', ',', '(', ')'].includes(letter.toLowerCase())) {
      wordLength += 0.3
      countLetter += 0.3
    } else if (['m', 'w'].includes(letter.toLowerCase())) {
      wordLength += 1
      countLetter += 1
    } else {
      wordLength += 0.6
      countLetter += 0.6
    }
    newWord += letter
    if (countLetter >= MAX_LENGTH_PER_ROW) {
      arrPerRow.push(text[index + 1] && text[index + 1] !== ' ' ? `${newWord}-` : newWord)
      result.push(arrPerRow.join(' ').trim())
      arrPerRow.splice(0, arrPerRow.length)
      countLetter = 0
      newWord = ''
    }
  })
  return { newWord, countLetter, wordLength }
}

export const breakWords = words => {
  const text = words.replace(/\s/g, ' ')
  const arr = text.split(' ')
  let counter = 0
  const result = []
  let arrPerRow = []
  arr.forEach((word, index) => {
    const { newWord, countLetter, wordLength } = countWord(word, counter, arrPerRow, result)
    if (counter !== 0 && counter + wordLength > MAX_LENGTH_PER_ROW && arrPerRow.length) {
      result.push(arrPerRow.join(' '))
      counter = 0
      arrPerRow = []
    }
    arrPerRow.push(newWord)
    if (newWord !== word) {
      counter = countLetter + 1
    } else {
      counter += wordLength + 1
    }

    if (index === arr.length - 1) {
      result.push(arrPerRow.join(' '))
    }
  })
  return result
}

export const downloadFile = (blob, fileName) => {
  const link = document.createElement('a')
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  // some browser needs the anchor to be in the doc
  document.body.append(link)
  link.click()
  link.remove()
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000)
}

export const getDateRange = (minDay = 7, maxDay = 0, format = 'DD/MM/YYYY') => {
  let date_start = formatDate(new Date(), format, true, 0)
  let date_end = formatDate(new Date(), format, true, 0)
  if (minDay > 0) {
    date_start = formatDate(new Date(Date.now() - minDay * 24 * 60 * 60 * 1000), format, true, 0)
  }
  if (maxDay > 0) {
    date_end = formatDate(new Date(Date.now() + maxDay * 24 * 60 * 60 * 1000), format, true, 0)
  }
  const date = {
    date_start,
    date_end,
  }
  return date
}

export const mappingDateRange = (defaultValue, obj) => {
  let dates = obj
  let obj_date = {
    date_start: defaultValue.date_start,
    date_end: defaultValue.date_end,
  }
  if (obj.date_range && obj?.date_range !== '') {
    const date_range = obj.date_range.split(' - ')
    if (date_range.length > 0) {
      obj_date = {
        date_start: date_range[0],
        date_end: date_range[1],
      }
    }
  }
  dates.date_start = obj_date.date_start
  dates.date_end = obj_date.date_end
  return dates
}

export const FindFileType = value => {
  return value.substring(value.lastIndexOf('.') + 1)
}
