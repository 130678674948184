import { LIST_MENU } from './action'

export const StoreMenu = data => {
  return async dispatch => {
    dispatch({
      type: LIST_MENU,
      data,
    })
  }
}
