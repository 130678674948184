import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'

const InteractorMasterCategoryMinorView = () => {
  const { t } = useTranslation()

  const categoryMinorView = useCallback(
    (payload, callback, loading) => {
      ApiService.jsonRequest('/master-category/minor-view', payload, response => {
        if (response.status_code === 200) {
          callback(response.data)
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 1500,
            redirect: '/master/category-minor/manage',
          })
        }
        loading(false)
      })
    },
    [t],
  )

  return { categoryMinorView }
}

export default InteractorMasterCategoryMinorView
