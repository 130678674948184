import { useCallback } from 'react'
import ApiService from '../../services/ApiService'

const InteractorCategorySummary = () => {
  const categorySummary = useCallback((body, callback) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/master-category/main-summary', payload, response => {
      if (response.status_code === 200) {
        callback(response.data)
      }
    })
  }, [])

  return { categorySummary }
}

export default InteractorCategorySummary
