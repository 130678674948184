import { useCallback } from 'react'
import ApiService from '../../../../services/ApiService'

const InteractorFundBalanceHistorySearch = () => {
  const fundBalanceHistorySearch = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/fund-balance/history-search', payload, response => {
      if (response.status_code === 200) {
        loading(false)
        callback(response.data)
      }
    })
  }, [])
  return { fundBalanceHistorySearch }
}

export default InteractorFundBalanceHistorySearch
