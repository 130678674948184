import Styled from 'styled-components'

const StyleIndex = Styled.div`

    .ant-table-content::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .ant-table-content::-webkit-scrollbar:vertical {
        width: 16px;
    }
    
    .ant-table-content::-webkit-scrollbar:horizontal {
        height: 16px;
    }
    
    .ant-table-content::-webkit-scrollbar-thumb {
        background-color: #E6E6E6;
        border-radius: 10px;
        border: 2px solid #F5F5F5;
    }
    
    .ant-table-content::-webkit-scrollbar-track {
        background-color: #F5F5F5; 
    }

    &.active{
        color: green;
        text-decoration: line-through;
    }
    .ant-input-search-button {
        height: 42px !important;
        width: 50px;
        color: #fafafa;
        background: #3984FB;
        border: 1px solid #3984FB;
    }
    .ant-input-affix-wrapper{
        padding:0 10px 0 5px !important;
    }
    .ant-input-group-addon{
        background: none;
        box-shadow: none; 
    }
    .btn-primary{
        a{
            display: inline-flex;
            align-items: center;
        }
    }
    .search-box{
        position: relative;
        box-shadow: 0 5px 5px rgba(#9299B8,.3);
        @media (max-width: 767px) {
            margin-bottom: 12px;
        }
        input{
            border: 0 none;
            height: 42px;
            width: 100%;
            max-width: 100%;
            padding: ${({ theme }) => (theme.rtl ? '0 20px' : '0 20px')};
            border-radius: 6px;
            &::placeholder{
                color: #ADB4D2;
            }
            &:focus{
                outline: none;
            }
        }
        .ant-select-single .ant-select-selector {
            padding: 0 20px;
            height: 45px !important;
            border: 1px solid #E3E6EF;
            .ant-select-selection-search, .ant-select-selection-placeholder, .ant-select-selection-item {
                display: flex;
                align-items: center;
            }
        }
        .ant-picker {
            min-width: 100%;
        }
    }
    .wrapper-table{
        position: relative;
        button {
            position:absolute;
            bottom:0px;
            svg{
                margin-left:5px;
            }
        }
    }
`
const StyleForm = Styled.div`
    .form-input{
        .info{
            background-color: transparent;
        }
    }
    @media only screen and (max-width: 767px) {
        .filter {
            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }
    .record-form-actions{
        padding-right: 0px !important;
        button:not(:last-child){
            margin-right: 20px;
            @media only screen and (max-width: 767px) {
                margin-right: 0px;
            }
        }
        @media only screen and (max-width: 767px) {
            text-align: center;
            button{
                ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
            }
        }
    }
    .pro-image{
        position: relative;
        margin-bottom: 30px;
        .ant-spin.ant-spin-spinning{
            position: absolute;
            top: 0;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
            width: 120px;
            height: 120px;
            background: #ffffff90;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 10;
            &:after{
                position: absolute;
                ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: #272B4120;
                content: '';
                z-index: -1;
            }
            .ant-spin-dot {
                position: relative;
                z-index: 10;
            }
        }
        img{   
            max-width: 120px;
            min-width: 120px;
            min-height: 120px;
            border-radius: 50%;
        }
        .ant-spin{
            height: 120px;
            width: 120px;
            display: flex;
            align-items: center;
        }
        .ant-upload-select{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 80px;
            bottom: -5px;
            height: 40px;
            width: 40px;
            background: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 222;
            span{
                display: inline-flex;
                height: 32px;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 32px;
                background:  ${({ theme }) => theme['primary-color']};
            }
        }
        .upload-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
        }
        .info{
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
            h1{
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 0;
            }
        }
        .ant-upload-list-item{
            margin-top: 0;
            &:hover{
                .ant-upload-list-item-info{
                    background-color: transparent;
                }
            }
            .ant-upload-list-item-info{
                >span{
                    display: flex;
                    align-items: center;
                    ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 14px;
                    ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 10px;
                }
                .ant-upload-list-item-card-actions {
                    /* // top: -8px; */
                }
            }
        }
    }
    .record-spin{
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-checkbox-wrapper{
        display: flex;
    }
    .ant-radio-button-wrapper {
        min-width: 100px;
        text-align: center;
    }
`
const StyleView = Styled.div`
    
    img{
        height: 100%;
        max-width: 120px;
    }
    .card-image-profile{
        width: 120px;
        height: 120px;
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    .title{
        width:100%;
        text-align:center;
        p{
            margin: 0px;
            font-weight:light;
            color: #999999;
            &:first-child{
                font-weight:bold;
                font-size:18px;
                color: #000000;
            }
        }
    }
    .ant-card{
        @media only screen and (max-width: 767px) {
            min-height: 80vh;
        }
        .ant-card-head-title{
            p{
                margin-bottom: 0px;
            }
            span{
                margin-left: 0px;
                font-size: 14px;
            }
        }
        @media only screen and (max-width: 575px){
            .ant-card-head-wrapper{
                flex-flow: row;
            }
            img{
                height: 100%;
                max-width: 90px;
            }
        }
    }
    .remove-colon{
        .ant-col{
            &:nth-child(odd)::after{
                content: none !important;
            }
        }
    }
    .form-view{
        .ant-col{
            >div {
                display: inline-block;
            }
            position:relative;
            word-break: keep-all;
            display: flex;
            align-self: stretch;
            &:nth-child(odd)::after{
                position: absolute;
                margin-left: 6px;
                content: ":";
                right:0;
                top:0;
            }
            .editor-content{
                padding: 0px 0px 8px;
                word-break: keep-all;
            }
        }
    }
`
const StyleCardWrapper = Styled.div`
    .ant-card{
        background: #F8F9FB;
    }
    .ant-card-head{
        border-color: #E3E6EF;
        background: #F8F9FB;
    }
    .ant-card-head .ant-card-head-title{
        padding: 12px 0;
    }
    .ant-card-head .ant-card-extra{
        display: block;
        padding: 12px 0;
        @media only screen and (max-width: 575px){
            margin-bottom: 4px;
    }
    }
    .ant-card.ant-card-bordered .ant-card-head{
        background: #fff;
    }
    .ant-card-head-wrapper .ant-card-extra a{
        color: #5F63F2;
    }
    .ant-card-body{
        padding: 22px 25px 15px 25px !important
        .ant-tree{
            background: #F8F9FB; 
        }
    }
    .ant-card-body p{
        margin-bottom: 4px;
        color: ${({ theme }) => theme['gray-color']};
    }
    .ant-card.ant-card-bordered {
        border-radius: 5px;
        overflow: hidden;
        background: #fff;
        border: 1px solid #E3E6EF !important;
    }
    .ant-card-small > .ant-card-head{
        font-size: 16px;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .ant-card-small > .ant-card-body{
        padding: 12px 15px 8px 15px !important
    }
`
const StyleNotification = Styled.div`
    .bubble{
        position: relative;
        text-decoration: none;
        font-size: 15px;
        background-color: #FFF;
        color: #334141;
        padding: 6px 4px;
        max-width: 300px;
        .ant-divider{
            margin: 4px 0;
        }
        a{
            color: #fafafa;
        }
        p{
            word-break: break-all;
            margin-bottom: 0px;
            display: -webkit-box; 
            overflow: hidden;
        }
        strong{
            color: #011826;
        }
        .top{
            display: flex;
            align-items: center;
            height: 100%;
            .fa{
                display: flex;
                color: #FFF;
                justify-content: center;
                align-items: center;
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
                font-size: 16px;
                border-radius: 100px
                background: #F03131;
                min-width: 26px;
                min-height: 26px;
            }
            
        }
        .bottom{
            display: flex;
            justify-content: end;
            font-weight: 600;
        }
    }
`

export { StyleIndex, StyleForm, StyleView, StyleCardWrapper, StyleNotification }
