import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../services/ApiService'
import { confirmationAlert, showAlert } from '../../utility/SweetAlert'

const InteractorStorageDelete = () => {
  const { t } = useTranslation()
  const storageDelete = useCallback(
    (body, loading) => {
      const { uid, updated_by, file_name } = body
      const payload = {
        uid,
        updated_by,
      }
      confirmationAlert({
        message: `${t('message.confirm_delete')} ${file_name} ?`,
        icon: 'question',
      }).then(result => {
        if (result === true) {
          ApiService.jsonRequest('/storage-data/delete', payload, response => {
            loading(false)
            if (response.status_code === 200) {
              showAlert({
                icon: 'success',
                message: t('message.successfully_deleted'),
                timer: 1500,
                redirect: '/storage/manage',
              })
            } else {
              showAlert({
                icon: 'error',
                message: t(`response_message.${response.messages}`),
                timer: 1500,
              })
            }
          })
        }
      })
    },
    [t],
  )
  return { storageDelete }
}

export default InteractorStorageDelete
