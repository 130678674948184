import { useCallback } from 'react'
import ApiService from '../../../../services/ApiService'

const InteractorFundAccountBankSearch = () => {
  const fundAccountBankSearch = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/fund-account/bank-search', payload, response => {
      if (response.status_code === 200) {
        loading(false)
        callback(response.data)
      }
    })
  }, [])
  return { fundAccountBankSearch }
}

export default InteractorFundAccountBankSearch
