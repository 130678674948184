import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../services/ApiService'
import { showAlert } from '../../utility/SweetAlert'

const InteractorTicketView = () => {
  const { t } = useTranslation()

  const ticketView = useCallback(
    (payload, callback, loading) => {
      ApiService.jsonRequest('/communication-ticket/view', payload, response => {
        loading(false)
        if (response.status_code === 200) {
          callback(response.data)
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 1500,
            redirect: '/ticket/manage',
          })
        }
      })
    },
    [t],
  )

  return { ticketView }
}

export default InteractorTicketView
