import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorContentBannerSearch = () => {
  const bannerSearch = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/content-banner/search', payload, response => {
      if (response.status_code === 200) {
        loading(false)
        callback(response.data)
      }
    })
  }, [])
  return { bannerSearch }
}

export default InteractorContentBannerSearch
